<template>
  <v-app id="register-profesional-perusahaan">
    <header-register v-if="register" :step="2" />
    <v-container fluid>
      <div class="main-container">
        <div class="pb-6" style="width: 80vh">
          <v-row>
            <v-col>
              <template v-if="register">
                <v-row>
                  <v-col class="txt-sub-6" cols="12"> Data Perusahaan </v-col>
                </v-row>

                <v-row class="mt-n2">
                  <v-col class="txt-sub-1"> Lengkapi data di bawah </v-col>
                </v-row>
              </template>

              <v-row class="mt-8">
                <v-col
                  class="d-flex"
                  cols="12"
                  sm="4"
                  style="flex-direction: column"
                >
                  <div class="txt-sub-1">
                    Bentuk Badan Usaha <span class="symbol-required">*</span>
                  </div>
                </v-col>

                <v-col cols="12" sm="8">
                  <v-custom-select
                    v-model="selectedBadanUsaha"
                    :options="badanUsaha"
                    :clearable="false"
                    label="value"
                  />
                </v-col>
              </v-row>

              <v-row class="mt-5">
                <v-col class="txt-sub-1" cols="12" sm="4">
                  Nama Badan Usaha <span class="symbol-required">*</span>
                </v-col>

                <v-col cols="12" sm="8">
                  <v-text-field
                    v-model="namaBadanUsaha"
                    name="nama-badan-usaha"
                    type="text"
                    outlined
                    autocomplete="off"
                    dense
                    counter
                    maxlength="100"
                    color="#36AC87"
                    placeholder="Misal: Berkah Jaya Mandiri"
                  />
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col class="txt-sub-1" cols="12" sm="4">
                  Nama Komersil <span class="symbol-required">*</span>
                </v-col>

                <v-col cols="12" sm="8">
                  <v-text-field
                    v-model="namaKomersil"
                    name="nama-komersil"
                    type="text"
                    outlined
                    autocomplete="off"
                    color="#36AC87"
                    dense
                    placeholder="Misal: Chatime"
                    counter
                    maxlength="100"
                  />
                </v-col>
              </v-row>

              <v-row class="mt-n4">
                <v-col class="txt-sub-1" cols="12" sm="4">
                  Bidang Usaha <span class="symbol-required">*</span>
                </v-col>

                <v-col cols="12" sm="8">
                  <v-custom-select
                    v-model="selectedBidangUsaha"
                    :options="bidangUsaha"
                    :clearable="false"
                    label="sector_name"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col class="txt-sub-1" cols="12" sm="4">
                  Deskripsi Singkat <span class="symbol-required">*</span>
                </v-col>

                <v-col
                  class="d-flex"
                  cols="12"
                  sm="8"
                  style="flex-direction: column"
                >
                  <div>
                    <v-textarea
                      v-model="deskripsiUsaha"
                      rows="4"
                      no-resize
                      outlined
                      color="#36AC87"
                      placeholder="Masukkan Deskripsi Singkat"
                      :rules="[
                        (v) =>
                          (v || '').length <= 300 || 'Maksimal 300 Karakter',
                      ]"
                    />
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="txt-sub-1" cols="12" sm="4">
                  Nomor Telepon <span class="symbol-required">*</span>
                </v-col>

                <v-col cols="12" sm="8">
                  <v-text-field
                    v-model="nomorTelepon"
                    name="nomor-telepon"
                    type="text"
                    outlined
                    autocomplete="off"
                    dense
                    color="#36AC87"
                    placeholder="Masukkan Nomor Telepon"
                  />
                </v-col>
              </v-row>

              <v-row class="mt-n4">
                <v-col class="txt-sub-1" cols="12" sm="4"> Email </v-col>

                <v-col cols="12" sm="8">
                  <v-text-field
                    v-model="email"
                    name="email"
                    type="text"
                    outlined
                    autocomplete="off"
                    color="#36AC87"
                    dense
                    placeholder="Masukkan Email"
                    counter
                maxlength="100"
                  />
                </v-col>
              </v-row>

              <v-row class="mt-n4">
                <v-col class="txt-sub-1" cols="12" sm="4"> Website </v-col>

                <v-col cols="12" sm="8">
                  <v-text-field
                    v-model="website"
                    name="website"
                    type="text"
                    outlined
                    color="#36AC87"
                    autocomplete="off"
                    dense
                    placeholder="Masukkan Website"
                    counter
                maxlength="200"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col class="txt-sub-1" cols="12" sm="4">
                  Domisili <span class="symbol-required">*</span>
                </v-col>

                <v-col
                  class="d-flex"
                  cols="12"
                  sm="8"
                  style="flex-direction: column"
                >
                  <div>
                    <v-custom-select
                      v-model="selectedKabupatenKota"
                      :options="kabupatenKota"
                      :clearable="false"
                      label="name_address"
                      @search="getAddress"
                      @option:selected="getKodePos"
                    >
                      <!-- eslint-disable-next-line vue/no-unused-vars  -->
                      <template #no-options="{ search, searching, loading }">
                        <span class="mr-10"> Ketik minimal 3 huruf </span>
                      </template>
                    </v-custom-select>
                  </div>
                </v-col>
              </v-row>

              <!-- <v-row>
                <v-col
                  class="txt-sub-1 d-flex"
                  cols="12"
                  sm="4"
                  style="align-items: center"
                >
                  <div>Akta Pendirian Perusahaan</div>
                </v-col>

                <v-col cols="12" sm="8">
                  <upload-button
                    :is-loading="isLoading[1]"
                    :upload-file="uploadedFile[1]"
                    @uploadButtonClick="uploadFile(1)"
                  />
                </v-col>
              </v-row>

              <v-row class="mt-n4">
                <v-col
                  class="txt-sub-1 d-flex"
                  cols="12"
                  sm="4"
                  style="align-items: center"
                >
                  <div>SIUP/ SIUJK/ IUI</div>
                </v-col>

                <v-col cols="12" sm="8">
                  <upload-button
                    :is-loading="isLoading[2]"
                    :upload-file="uploadedFile[2]"
                    @uploadButtonClick="uploadFile(2)"
                  />
                </v-col>
              </v-row>

              <v-row class="mt-n4">
                <v-col
                  class="txt-sub-1 d-flex"
                  cols="12"
                  sm="4"
                  style="align-items: center"
                >
                  <div>Tanda Daftar Perusahaan</div>
                </v-col>

                <v-col cols="12" sm="8">
                  <upload-button
                    :is-loading="isLoading[3]"
                    :upload-file="uploadedFile[3]"
                    @uploadButtonClick="uploadFile(3)"
                  />
                </v-col>
              </v-row>

              <v-row class="mt-n4">
                <v-col
                  class="txt-sub-1 d-flex"
                  cols="12"
                  sm="4"
                  style="align-items: center"
                >
                  <div>NPWP</div>
                </v-col>

                <v-col cols="12" sm="8">
                  <upload-button
                    :is-loading="isLoading[4]"
                    :upload-file="uploadedFile[4]"
                    @uploadButtonClick="uploadFile(4)"
                  />
                </v-col>
              </v-row>

              <v-row class="mt-n4">
                <v-col
                  class="txt-sub-1 d-flex"
                  cols="12"
                  sm="4"
                  style="align-items: center"
                >
                  <div>SK Kumham</div>
                </v-col>

                <v-col cols="12" sm="8">
                  <upload-button
                    :is-loading="isLoading[5]"
                    :upload-file="uploadedFile[5]"
                    @uploadButtonClick="uploadFile(5)"
                  />
                </v-col>
              </v-row>

              <v-row class="mt-n4 mb-8">
                <v-col
                  class="txt-sub-1 d-flex"
                  cols="12"
                  sm="4"
                  style="align-items: center"
                >
                  <div>KTP Pimpinan</div>
                </v-col>

                <v-col cols="12" sm="8">
                  <upload-button
                    :is-loading="isLoading[6]"
                    :upload-file="uploadedFile[6]"
                    @uploadButtonClick="uploadFile(6)"
                  />
                </v-col>
              </v-row> -->

              <!-- Alert -->
              <v-row class="mb-n8">
                <v-col>
                  <v-alert v-model="showAlert" type="error" dismissible>
                    {{ alertText }}
                  </v-alert>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-btn
                    class="text-capitalize"
                    color="#36AC87"
                    block
                    x-large
                    small
                    @click="submit"
                  >
                    <template v-if="register">
                      <span class="txt-login"> Lanjutkan Pendaftaran </span>
                    </template>
                    <template v-else>
                      <span class="txt-login"> Simpan </span>
                    </template>
                  </v-btn>
                </v-col>
              </v-row>

              <!-- <template v-if="register">
                <v-row>
                  <v-col class="text-center">
                    <span
                      class="txt-14-gray-80"
                      style="cursor: pointer"
                      @click="navBack"
                    >
                      <v-icon class="pb-1">
                        mdi-chevron-left
                      </v-icon>
                      Kembali ke halaman sebelumnya
                    </span>
                  </v-col>
                </v-row>
              </template> -->
            </v-col>
          </v-row>
        </div>
      </div>
      <!-- File Upload -->
      <!-- <v-file-input
        id="upload-file-1"
        style="display: none"
        @change="upload"
      />
      <v-file-input
        id="upload-file-2"
        style="display: none"
        @change="upload"
      />
      <v-file-input
        id="upload-file-3"
        style="display: none"
        @change="upload"
      />
      <v-file-input
        id="upload-file-4"
        style="display: none"
        @change="upload"
      />
      <v-file-input
        id="upload-file-5"
        style="display: none"
        @change="upload"
      />
      <v-file-input
        id="upload-file-6"
        style="display: none"
        @change="upload"
      /> -->
    </v-container>
  </v-app>
</template>

<script>
import axios from "axios";
// import { mapState } from 'vuex'
import RegistrasiHeader from "../RegistrasiHeader.vue";
import UploadButton from "../../komponen/UploadButton.vue";
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.post["x-api-key"] = localStorage.getItem("token");

export default {
  components: {
    "upload-button": UploadButton,
    "header-register": RegistrasiHeader,
  },

  props: {
    register: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    // Form Data:
    selectedBadanUsaha: [
      {
        value: "Pilih Badan Usaha",
      },
    ],
    badanUsaha: [],

    namaBadanUsaha: null,

    namaKomersil: null,

    selectedBidangUsaha: [
      {
        sector_name: "Pilih Bidang Usaha",
      },
    ],
    bidangUsaha: [],
    deskripsiUsaha: null,

    // kodeKBLI: "",

    npwp: null,

    tanggal: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),

    modalTanggal: false,

    selectedKabupatenKota: [
      {
        name_address: "Kabupaten / Kota / Kecamatan",
      },
    ],
    kabupatenKota: [],

    selectedKodePos: [
      {
        label: "Pilih Kode Pos",
      },
    ],
    kodePos: [],

    alamatLengkap: null,

    nomorTelepon: null,

    email: null,

    website: null,

    selectedJumlahKaryawan: [
      {
        value: null,
        label: "Pilih Jumlah Karyawan",
      },
    ],
    jumlahKaryawan: [
      {
        value: "0_50",
        label: "0 - 50",
      },
      {
        value: "50_100",
        label: "50 - 100",
      },
      {
        value: "100_200",
        label: "100 - 200",
      },
      {
        value: "200_",
        label: "200 keatas",
      },
    ],

    organisasi: 3,
    disableButton: false,
    uploadKey: 0,

    hashAktaPendirianPerusahaan: null, // 1
    hashSiup: null, // 2
    hashTandaDaftarPerusahaan: null, // 3
    hashNPWP: null, // 4
    hashSKumham: null, // 5
    hashKtpPimpinan: null, // 6
    isLoading: {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
    },
    uploadedFile: {
      1: {},
      2: {},
      3: {},
      4: {},
      5: {},
      6: {},
    },

    // Alert:
    showAlert: false,
    alertText: "Error",
  }),

  computed: {},

  watch: {},

  created() {
    this.initialize();
    this.getBidangUsaha();
  },

  methods: {
    initialize() {
      const requestBody = {};

      axios
        .post("/v1/auth/register/init", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.badanUsaha = res.data.data.mc_type;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    getBidangUsaha() {
      const requestBody = {
        type_order: "name",
      };

      axios
        .post("/v1/general/get-company-sector", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.bidangUsaha = res.data.data.sector.list;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    getAddress(e) {
      if (e.length < 2) return;

      const requestBody = {
        search: e,
      };

      axios
        .post("/v1/general/get-address", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.kabupatenKota = res.data.data.address.list;
            this.kabupatenKotaDarurat = res.data.data.address.list;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    // getKodePos(e) {
    //   const requestBody = {
    //     hash_address: e.hash_address,
    //   };

    //   axios
    //     .post("/v1/general/get-postal-code", requestBody)
    //     .then((res) => {
    //       if (res.data.status === 200) {
    //         this.kodePos = res.data.data.postal_code.list;
    //         this.kodePosDarurat = res.data.data.postal_code.list;
    //       }
    //     })
    //     .catch((e) => {
    //       if (typeof e.response.data.error === "object") {
    //         this.$toast.error(Object.values(e.response.data.error)[0][0]);
    //       } else {
    //         this.$toast.error(e.response.data.error);
    //       }
    //     });
    // },

    // uploadFile(key) {
    //   this.uploadKey = key;
    //   document.getElementById("upload-file-" + key).click();
    // },

    // upload(e) {
    //   let upKey = this.uploadKey;
    //   this.isLoading[upKey] = true;
    //   const baseCDNurl = process.env.VUE_APP_CDN_BASE_URL;
    //   const config = {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   };
    //   const requestBody = new FormData();
    //   requestBody.append("file", e);
    //   requestBody.append("dest", "member_photo");

    //   axios
    //     .post(baseCDNurl + "/v1/upload", requestBody, config)
    //     .then((res) => {
    //       if (res.data.status === 200) {
    //         this.isLoading[upKey] = false;
    //         this.uploadedFile[upKey] = res.data.data;
    //         switch (upKey) {
    //           case 1:
    //             this.hashAktaPendirianPerusahaan = res.data.data.tmp_file_hash;
    //             break;

    //           case 2:
    //             this.hashSiup = res.data.data.tmp_file_hash;
    //             break;

    //           case 3:
    //             this.hashTandaDaftarPerusahaan = res.data.data.tmp_file_hash;
    //             break;

    //           case 4:
    //             this.hashNPWP = res.data.data.tmp_file_hash;
    //             break;

    //           case 5:
    //             this.hashSKumham = res.data.data.tmp_file_hash;
    //             break;

    //           case 6:
    //             this.hashKtpPimpinan = res.data.data.tmp_file_hash;
    //             break;
    //         }
    //       }
    //     })
    //     .catch((e) => {
    //       this.isLoading[upKey] = false;
    //       if (typeof e.response.data.error === "object") {
    //         this.$toast.error(Object.values(e.response.data.error)[0][0]);
    //       } else {
    //         this.$toast.error(e.response.data.error);
    //       }
    //     });
    // },

    submit(type) {
      console.log("submit");
      this.disableButton = true;

      // const org = [];
      // for (let index = 1; index < this.organisasi + 1; index++) {
      //   const dt = document.getElementById("organisasi-" + index).value;
      //   org.push(dt);
      // }

      const requestBody = {
        reg_hash: localStorage.getItem("step-1"),
        reg_mc_type: this.selectedBadanUsaha.key,
        reg_mc_name: this.namaBadanUsaha,
        reg_mc_brand: this.namaKomersil,
        reg_mc_sector: this.selectedBidangUsaha.sector_id,
        reg_mc_description: this.deskripsiUsaha,
        // reg_kbli_code: this.kodeKBLI,
        // reg_mc_npwp: this.npwp,
        // reg_mc_creation_date: this.tanggal,
        reg_mc_address_hash: this.selectedKabupatenKota.hash_address,
        // reg_mc_address_pos: this.selectedKodePos,
        // reg_mc_address: this.alamatLengkap,
        reg_mc_phone: this.nomorTelepon,
        reg_mc_email: this.email,
        reg_mc_website_url: this.website,
        // reg_mc_employee_count: this.selectedJumlahKaryawan.value,
        // reg_association: org,
        // reg_doc_akta: this.hashAktaPendirianPerusahaan,
        // reg_doc_siup: this.hashSiup,
        // reg_doc_tdp: this.hashTandaDaftarPerusahaan,
        // reg_doc_npwp: this.hashNPWP,
        // reg_doc_sk_kumham: this.hashSKumham,
        // reg_doc_ktp_direktur: this.hashKtpPimpinan,
      };

      let api = "/v1/auth/register/step-3";
      if (!this.register) api = "/v1/admin/setting/company/create";

      axios
        .post(api, requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            if (this.register) {
              localStorage.setItem("step-3", JSON.stringify(requestBody));
              this.$router.push({ name: "Password" });
            } else {
              this.$router.push({ name: "PengaturanPerusahaan" });
            }
          }
        })
        .catch((e) => {
          this.disableButton = false;

          if (typeof e.response.data.error === "object") {
            this.setAlert(Object.values(e.response.data.error)[0][0]);
          } else {
            this.setAlert(e.response.data.error);
          }
        });
    },

    navBack() {
      this.$router.push({ name: "RegisterProfesionalDataDiri" });
    },

    setAlert(txt) {
      this.showAlert = true;
      this.alertText = txt;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.txt-login {
  @extend .p-1;
  color: #ffffff;
  font-weight: bold;
}

.txt-sub-1 {
  @extend .p-1;
  color: $gray-50;
}

.txt-sub-2 {
  @extend .p-1;
  color: $gray-50;
  font-style: italic;
}

.txt-sub-3 {
  @extend .p-2;
  color: $gray-50;
}

.txt-sub-4 {
  @extend .p-2;
  color: $blue-1;
  cursor: pointer;
  font-weight: bold;
}

.txt-sub-5 {
  @extend .p-2;
  color: $gray-80;
}

.txt-sub-6 {
  @extend .h-5;
  color: $black-1;
  font-weight: bold;
}

.txt-btn-login {
  @extend .h-5;
  color: #ffffff;
  font-weight: bold;
}

.btn-login {
  color: $green-2;
}

.symbol-required {
  color: $red-1;
}

.txt-14-gray-80 {
  @include font-common;
  color: $gray-80;
}

.txt-12-gray-50 {
  @include font-common;
  color: $gray-50;
}
</style>
